<template>
    <v-card>
        <v-card-title>
            <span
                class="text-h5"
                v-html="$t('games.infoGame', {game: gameId})"
            />
        </v-card-title>

        <!-- Edited users-->
        <v-card-text>
            <v-container class="py-0">
                <info-board-dice-question
                    :game-id="gameId"
                    :item="item"
                />
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer />

            <v-btn
                color="blue darken-1"
                text
                @click="$router.go(-1)"
            >
                {{ $t(`common.endProcess`) }}
            </v-btn>
            <!--            <v-btn
                v-if="!isOnlyView && !item"
                color="blue darken-1"
                text
                :disabled="!isValid && !serverCheck"
                @click="save"
            >
                {{ $t('common.dialogSave') }}
            </v-btn>-->
        </v-card-actions>
    </v-card>
</template>

<script>
import axios from '@/plugins/axios'
import rules from '@/helpers/rulesValidate'
import { mapGetters } from 'vuex'

export default {
    components: {
        InfoBoardDiceQuestion: () => import('@/components/admin/games/boardDiceQuestion/infoBoardDiceQuestion')
    },
    data: function () {
        return {
            urlMain: 'admin/games/games',
            gameId: null,
            item: null,
            gameTypes: [],
            cardTypes: []
        }
    },
    computed: {
        hasCards() {
            if (!this.item?.game_type_id) return false;

            switch (+this.item.game_type_id) {
            case 1: return true; // if is a Jumanji game
            case 3: return true; // if is a Tablero-Dados-Preguntas game
            default: return false;
            }
        }

        // ...mapGetters(['role', 'cities'])
    },
    async created() {
        // const itemParam = this.$route.params.item;

        this.gameId = this.$route.params.id;

        /* if (itemParam) {
            this.item = itemParam;
        } */

        if (Number.isInteger(+this.gameId)) {
            await this.fetchGame();
        }

        // this.fetchGameTypes();
    },
    /* watch: {
        dialog(val) {
            if (val) {
                this.editedItem = this.getEditedItem()
            }
        }
    }, */
    mounted() {
    },
    methods: {

        initialize() {

        },
        /* async save() {
            const formData = new FormData();
            formData.append("game_type_id", this.editedItem.game_type_id.value);
            formData.append("teams_count", this.editedItem.teams_count.value ?? '');
            formData.append("active", this.editedItem.active.value ?? '');

            let urlAppend = ''
            let method = 'POST'
            if (this.editedItem.id) {
                urlAppend = '/' + this.editedItem.id
                method = 'PATCH'

                formData.append("_method", method);
            }

            try {
                const result = await axios
                    .post(this.urlMain + urlAppend, formData, {
                        headers: { "Content-Type": "multipart/form-data" }
                    });

                if (result.status === 200 || result.status === 201 || result.status === 204) {
                    this.afterCreate(result.data.game)

                    const actionMSG = this.editedItem.id ? 'updatedOK' : 'createdOK'
                    this.$store.dispatch('snackbar/success', this.$t('common.' + actionMSG))
                }
            } catch (error) {
                this.$store.dispatch('snackbar/error', this.$t('common.accionError'))
            }
        }, */
        async fetchGameTypes() {
            try {
                const urlTypes = 'admin/games/types';

                const gameTypes = await axios
                    .get(urlTypes)

                this.gameTypes = gameTypes.data.gameTypes;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        async fetchGame() {
            try {
                const game = await axios
                    .get(`${this.urlMain}/${this.gameId}`)

                this.item = game.data.game;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        }
    }
}
</script>

<style >

</style>
